import { CommonModule } from '@angular/common';
import {
  Component,
  computed,
  effect,
  input,
  signal,
  untracked,
} from '@angular/core';
import { ImageModule } from 'primeng/image';
import { Media } from '@models/media/media.model';
import { MediaService } from '@services/media.service';
import { SafeUrl } from '@angular/platform-browser';
import { ProgressBarModule } from 'primeng/progressbar';

@Component({
  selector: 'app-thumbnail',
  standalone: true,
  imports: [CommonModule, ImageModule, ProgressBarModule],
  templateUrl: './thumbnail.component.html',
  styleUrl: './thumbnail.component.scss',
})
export class ThumbnailComponent {
  media = input<Media>();
  name = computed(() => this.media()?.name ?? '');
  currentImageUri = signal<SafeUrl | string | undefined>(undefined);
  loading = signal<boolean>(false);

  constructor(public mediaService: MediaService) {
    effect(
      () => {
        const keyName = this.media()?.keyName;
        untracked(() => {
          if (keyName === undefined) return;
          this.getImage(keyName);
        });
      },
      { allowSignalWrites: true },
    );
  }

  getImage(keyName: string): void {
    this.loading.set(true);
    this.mediaService.getImageThumbnail(keyName).subscribe({
      next: (currentImageUri: SafeUrl | undefined) => {
        this.currentImageUri.set(currentImageUri ?? undefined);
      },
      complete: () => this.loading.set(false),
    });
  }
}
