<div class="image-container" style="width: 100px">
  @if (currentImageUri()) {
    <img [src]="currentImageUri()" [alt]="name()" style="object-fit: contain" />
  } @else if (loading()) {
    <p-progressBar
      class="w-full mt-3"
      mode="indeterminate"
      [style]="{ height: '10px' }"
    />
  } @else {
    <span>Pas d'image</span>
  }
</div>
